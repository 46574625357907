import { createAsync } from '@solidjs/router';
import { Title } from '@solidjs/meta';
import { Show, Suspense } from 'solid-js';
import { Section } from '@troon/ui';
import { IconStar } from '@troon/icons/star';
import { IconArrowsReload01 } from '@troon/icons/arrows-reload-01';
import { IconCreditCard01 } from '@troon/icons/credit-card-01';
import { IconGift } from '@troon/icons/gift';
import { IconGlobe } from '@troon/icons/globe';
import { IconUserSquare } from '@troon/icons/user-square';
import { requireLoggedIn } from '../providers/user';
import { SidebarLayout } from '../layouts/sidebar';
import type { NavigationMenuItem } from '@troon/ui';
import type { ComponentProps } from 'solid-js';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';
import type { LoggedInUserQuery } from '../graphql';

const userNav: Array<ComponentProps<typeof NavigationMenuItem> & { filter?: (user?: LoggedInUserQuery) => boolean }> = [
	{ icon: IconStar, href: '/account', children: 'Account' },
	{ icon: IconGlobe, href: '/account/activity', children: 'Activity' },
	{
		icon: IconArrowsReload01,
		href: '/account/access',
		children: 'Troon Access',
		filter: (user) => !!user?.activeTroonCardSubscription,
	},
	{ icon: IconUserSquare, href: '/account/profile', children: 'Profile' },
	{ icon: IconCreditCard01, href: '/account/payment-methods', children: 'Payment Methods' },
	{
		icon: IconGift,
		href: '/account/referrals',
		children: 'Refer Friends',
		filter: (user) => !!user?.activeTroonCardSubscription,
	},
];

export default function Account(props: RouteSectionProps) {
	const user = createAsync(() => requireLoggedIn(), { deferStream: true });

	return (
		<>
			<Title>My account | Troon</Title>
			<Suspense>
				<Show when={user()}>
					<SidebarLayout sidebar={userNav.filter((item) => !item.filter || item.filter(user()))}>
						<Section>{props.children}</Section>
					</SidebarLayout>
				</Show>
			</Suspense>
		</>
	);
}

export const route = { preload: () => requireLoggedIn() } satisfies RouteDefinition;
